import * as React from "react";
import { Link } from "gatsby";
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'

const lang = () => {
	return(
		<ModalRoutingContext.Consumer>
		{({ modal, closeTo }) => (
			<>
			  <Link 
			  	className="Modal-close Lang"
			  	to={closeTo}
			  	state={{noScroll: true }}
			  >
				<span></span>
			  </Link>
			<div className="Work-wrap Lang-modal">
				
				<ul className="Work-slides">
					<li className="spacer"></li>
					<li className="Title">
						<h3 className="font-title text-center">Langenstein's</h3>
					</li>
					<li className="Work lang-1">
						<div>
							<picture>
								<source srcset={'/work/lang/1/1-a.png'} media="(min-width: 59em)" />
								<source srcset={'/work/lang/1/1-b.png'} media="(min-width: 34em)" />
								<source srcset={'/work/lang/1/1-c.png'} media="(max-width: 34em)" />
								<img src={"/work/lang/1/1-a.png"} alt="Langenstein's Work Example" />
							</picture>
						</div>
					</li>
					<li className="Work lang-2">
						<div>
							<picture>
								<source srcset={'/work/lang/2/2-a.png'} media="(min-width: 59em)" />
								<source srcset={'/work/lang/2/2-b.png'} media="(min-width: 34em)" />
								<source srcset={'/work/lang/2/2-c.jpg'} media="(max-width: 34em)" />
								<img src={"/work/lang/2/2-a.png"} alt="Langenstein's Work Example" />
							</picture>
						</div>
					</li>
					<li className="Work">
						<div className="lang-goto goto">
							
							<p>Located in New Orleans, a city known for its unique cuisine, Langenstein’s has become the go-to spot for food lovers since 1922. Offering customers prepared New Orleans’ delicacies, gourmet items and their everyday grocery needs, Langenstein’s is not only the oldest full service grocery store in New Orleans but also a fifth generation owned and operated family business.</p>
							<a target="blank" rel="noreferrer" href="https://www.langensteins.com/"><p>View Site</p></a>
						</div>
					</li>
				</ul>
		  </div>
		  </>
		)}
	  </ModalRoutingContext.Consumer>
	)
}

export default lang